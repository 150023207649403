import styled from "styled-components";
import { media } from "./../../common/MediaQueries";

const InfoBannerStyled = styled.div`
  width: 100%;
  background-image: linear-gradient(
    137deg,
    ${({ theme }) => theme.colors.watermelon} 0%,
    ${({ theme }) => theme.colors.blue} 100%
  );
  color: ${({ theme }) => theme.colors.white};
  padding-top: 36px;
  padding-bottom: 36px;

  @media ${media.phone} {
    padding-top: 42px;
    padding-bottom: 42px;
  }
`;

export { InfoBannerStyled };
