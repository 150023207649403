import styled, { css } from "styled-components";
import { Link } from "gatsby";

import { transition } from "./../../common/mixins";
import { media, customMedia } from "./../../common/MediaQueries";

const CtaStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  @media ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const CtaInnerStyled = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  margin-top: -130px;

  @media ${media.phone} {
    margin-top: -160px;
  }

  @media ${media.tablet} {
    margin: 0;
  }
`;

const CtaContentStyled = styled.div`
  @media ${customMedia.maxTablet} {
    order: 2;
    padding-top: 10px;
  }
  @media ${media.tablet} {
    width: 64%;
    padding-right: 2rem;
  }
`;

const CtaContentHeadingStyled = styled.div`
  max-width: 100%;
  margin-bottom: 30px;

  mark, i {
    margin: 0;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.watermelon};
  }
  
  @media ${media.phone} {
    margin-bottom: 0.5rem;
  }
`;

const CtaContentSubheadingStyled = styled.div`
  margin-bottom: 45px;
  @media ${media.phone} {
    margin-bottom: 50px;
  }
`;

const CtaMediaStyled = styled.div`
  @media ${customMedia.maxTablet} {
    order: 1;
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
  }
  @media ${media.tablet} {
    width: 36%;
  }
`;

const CtaMediaContainerStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  width: 241px;
  height: 234px;

  @media ${media.phone} {
    width: 351px;
    height: 351px;
  }
`;

const CtaMediaMaskStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 60px solid ${({ theme }) => theme.colors.white};
  background: transparent;
  opacity: 0.6;

  @media ${media.phone} {
    border: 92px solid ${({ theme }) => theme.colors.white};
    opacity: 1;
  }
`;

const CtaMediaItemStyled = styled.div`
  max-width: 138px;
  margin: 0 auto;
  width: 100%;
  @media ${media.phone} {
    max-width: 203px;
  }
`;

interface CtaButtonStyledProps {
  $isLeft?: boolean;
  $isRight?: boolean;
}

const CtaButtonStyled = styled(Link)<CtaButtonStyledProps>`
  ${transition};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-size: 1rem;
  line-height: 40px;
  height: 60px;
  text-align: center;
  border-radius: 10px;

  &:hover {
    background: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ $isLeft }) =>
    $isLeft &&
    css`
      background: ${({ theme }) => theme.colors.main};
      color: ${({ theme }) => theme.colors.white};

      @media ${media.tablet} {
        border-radius: 10px 0 0 10px;
      }
    `}

  ${({ $isRight }) =>
    $isRight &&
    css`
      color: ${({ theme }) => theme.colors.main};
      background-color: ${({ theme }) => theme.colors.white};

      @media ${media.tablet} {
        border-radius: 0 10px 10px 0;
      }
    `}
`;

const CtaButtonStyledNative = styled.a<CtaButtonStyledProps>`
  ${transition};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-size: 1rem;
  line-height: 40px;
  height: 60px;
  text-align: center;
  border-radius: 10px;

  &:hover {
    background: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ $isLeft }) =>
    $isLeft &&
    css`
      background: ${({ theme }) => theme.colors.main};
      color: ${({ theme }) => theme.colors.white};

      @media ${media.tablet} {
        border-radius: 10px 0 0 10px;
      }
    `}

  ${({ $isRight }) =>
    $isRight &&
    css`
      color: ${({ theme }) => theme.colors.main};
      background-color: ${({ theme }) => theme.colors.white};

      @media ${media.tablet} {
        border-radius: 0 10px 10px 0;
      }
    `}
`;

const CtaContentActionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${CtaButtonStyled},
  ${CtaButtonStyledNative} {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  @media ${media.tablet} {
    flex-direction: row;
    
    ${CtaButtonStyled},
    ${CtaButtonStyledNative} {
      &:first-child {
        margin-right: 5px;
      }
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
`;

interface CtaWrapperStyledProps {
  isAlt?: boolean;
}

const CtaWrapperStyled = styled.div<CtaWrapperStyledProps>`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.watermelon};
  color: ${({ theme }) => theme.colors.white};
  padding: 36px 0;

  ${({ isAlt }) =>
    isAlt &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.main};
      ${CtaContentSubheadingStyled} {
        color: ${({ theme }) => theme.colors.blue};
      }
      ${CtaButtonStyled},
      ${CtaButtonStyledNative} {
        &:first-child {
          background: ${({ theme }) => theme.colors.watermelon};
        }
        &:nth-child(2) {
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.main};
        }
        &:hover {
          background-color: ${({ theme }) => theme.colors.blue};
        }
      }
      ${CtaMediaMaskStyled} {
        display: none;
      }
      ${CtaMediaItemStyled} {
        max-width: 320px;
      }
    `}

  @media ${media.tablet} {
    padding: 120px 0 158px;
  }
`;

export {
  CtaWrapperStyled,
  CtaInnerStyled,
  CtaStyled,
  CtaContentStyled,
  CtaContentHeadingStyled,
  CtaContentSubheadingStyled,
  CtaContentActionsStyled,
  CtaMediaStyled,
  CtaMediaContainerStyled,
  CtaMediaItemStyled,
  CtaButtonStyled,
  CtaMediaMaskStyled,
  CtaButtonStyledNative,
};
