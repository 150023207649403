import styled, { css } from "styled-components";
import { Link } from "gatsby";

import { transition } from "./../../common/mixins";
import { customMedia, media } from "./../../common/MediaQueries";

const BlogItemMediaStyled = styled.div`
  height: 212px;
  overflow: hidden;
  .gatsby-image-wrapper {
    ${transition};
  }
`;

const BlogItemContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: ${({ theme }) => theme.fontFamilies.proximaNova};
  padding: 24px 20px;
`;

const BlogItemTitleStyled = styled.div`
  font-size: 20px;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 6px;
`;

const BlogItemSubtitleStyled = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 20px;

  span {
    color: ${({ theme }) => theme.colors.watermelon};
  }
`;

const BlogItemDescStyled = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  word-wrap: break-word;
`;

const BlogItemActionStyled = styled.div`
  margin-top: auto;
`;

const BlogItemActionButtonStyled = styled.div`
  ${transition};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.watermelon};
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  min-width: 120px;
  padding: 5px 10px;
  text-align: center;
  height: 30px;
  border-radius: 6px;
  outline: 0;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  &:hover {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

const BlogItemStyled = styled(Link)`
  ${transition};
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);

  &:hover {
    box-shadow: none;
    ${BlogItemMediaStyled} {
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
    }
  }
`;

export {
  BlogItemStyled,
  BlogItemMediaStyled,
  BlogItemContentStyled,
  BlogItemTitleStyled,
  BlogItemSubtitleStyled,
  BlogItemDescStyled,
  BlogItemActionStyled,
  BlogItemActionButtonStyled,
};
